import React, { VFC, useState, useEffect } from "react";

import { LinkIconButton } from "../components/LinkIconButton";
import { TCanvas } from "../components/TCanvas";
import { NoiseLayer } from "../components/NoiseLayer";
import { LeftMenu } from "../components/LeftMenu";
import { Link } from "react-router-dom";
import companyLogoImage from "../assets/images/companylogo-leftcorner.png";
import { client } from "../lib/sanityClient";
import companyName from "../assets/images/company-name.png";


const Work = () => {
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    fetchCollectionData();
  }, []);

  const fetchCollectionData = async (sanityClient = client) => {
    const query = `*[_type == "category" ] | order(title){
      title,
  _id
    }`;

    const collectionData = await sanityClient.fetch(query);


    // the query returns 1 object inside of an array
    await setMenu(collectionData);
  };

  return (
    <>
      <div className="page-company-logo">
        <Link to="/">
          <img src={companyLogoImage} />
        </Link>
      </div>
      <div className="page-company-name">
        <Link to="/">
          <img src={companyName} />
        </Link>
      </div>
      <div className="siteContainer">
          <div className="mywork-container">
            <ul id="work-category-items">
              {menu.map((menuItem: any, id: any) => (
                <li key={id}>
                  <Link to={`/work/${menuItem._id}`}>
                    {menuItem?.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <LeftMenu />


   
      </div>
    </>
  );
};

export default Work;
