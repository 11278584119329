import React, { VFC, useState, useEffect } from "react";
import { LinkIconButton } from "../components/LinkIconButton";
import { TCanvas } from "../components/TCanvas";
import { NoiseLayer } from "../components/NoiseLayer";
import { LeftMenu } from "../components/LeftMenu";
import { Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import btnCloseImage from "../assets/images/btn-close.png";
import mapImage from "../assets/images/map.png";




import { client } from "../lib/sanityClient";

const defaultProps = {
  center: {
    lat: 22.31093726944246,
    lng: 114.18879934280635,
  },
  zoom: 18,
};

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

const AnyReactComponent = (text: any) => {

  console.log('AnyReactComponent >>>>>');
  return (
    <div className="comapnylogo-map">
      <img src={mapImage} />
    </div>

  );
};

const Contact = () => {
  const [phone, setPhone] = useState([]);
  const [address, setAddress] = useState([]);
  const [email, setEmail] = useState([]);
  const [lat, setLat] = useState(22.31093726944246);
  const [lng, setLng] = useState(114.18879934280635);
  const [centerPoint, setCenterPoint] = useState(null);

  useEffect(() => {
    fetchCollectionData();
  }, []);

  const fetchCollectionData = async (sanityClient = client) => {
    const query = `*[_type == "contact" ] | order(title){
      address,
      contactNumber,
      email,
      lat,
       lng,
  centerPoint
}`;

    const collectionData = await sanityClient.fetch(query);

    // the query returns 1 object inside of an array
    await setPhone(collectionData[0].contactNumber);
    await setAddress(collectionData[0].address);
    await setEmail(collectionData[0].email);

    console.log("center point   >>>>");
    console.log(collectionData[0].centerPoint);
    await setLat(collectionData[0].centerPoint.lat);
    await setLng(collectionData[0].centerPoint.lng);


    await setCenterPoint(collectionData[0].centerPoint);

    // await setCenterPoint({
    //   lat: collectionData[0].lat,
    //   lng: collectionData[0].lng,
    // });
  };

    if(centerPoint === null){
      return (
        <>
        <div className="aboutus-close-button">
          <Link to="/">
            <img src={btnCloseImage} />
          </Link>
        </div>
  
        <div className="siteContainer">
          <div className="contactUsContentContainer">
            <p>Loading...</p>
          </div>
        </div>
      </>
      );
    }



  return (
    <>
      <div className="aboutus-close-button">
        <Link to="/">
          <img src={btnCloseImage} />
        </Link>
      </div>

      <div className="siteContainer">
        <div className="contactUsContentContainer">
          <div className="contactus-map">
              <GoogleMapReact
                options={{
                  styles: mapStyle,
                }}
                bootstrapURLKeys={{
                  key: "AIzaSyAEC-em4syPVV7ItCc0BgAqFX1LaPgRbT8",
                }}
                defaultCenter={centerPoint}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent lat={lat} lng={lng} text="Office" />
              </GoogleMapReact>
         
          </div>

          <div className="contactUsContent">
            <div style={{ paddingTop: "20px" }}>
              <p>{address}</p>
              <p>{phone}</p>
              <p>{email}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
