import sanityClient from '@sanity/client'

export const client = sanityClient({
  projectId: 'pqqrzvpj',
  dataset: 'production',
  useCdn: true,
  token:
    'skr2E1np9ocuFAR6TRutr7ynryLNNtgUnfcka9Sa9SuL7102lNhg9PthZxAwhGtha8JgcIOt3sNqr2C79TwrjieUybnytvndEgiwGwqmLHoNOWtNMgUsHkmPWbND22HRUezt02ua3p1Ef4eNlcTAVxLkqRAR4TMHDtWOuBDXqK5DhN1ungIO', // or leave blank to be anonymous user
  apiVersion: '2022-02-03', // or leave blank for latest stable version
})
