import React, { VFC } from "react";
import { LinkIconButton } from "./LinkIconButton";
import { TCanvas } from "./TCanvas";

export const NoiseLayer: VFC = () => {
  return (
    <>
      <div className="site-background-noise">

      </div>
    </>
  );
};
