import React, { VFC, useState, useEffect } from "react";
import { LinkIconButton } from "./LinkIconButton";
import { TCanvas } from "./TCanvas";
import { NoiseLayer } from "./NoiseLayer";
import { Link, NavLink } from "react-router-dom";
import companyLogoImage from "../assets/images/companylogo-leftcorner.png";


export const LeftMenu = () => {

  return (
    <>
      <div className="menucontainer">
        <ul id="menu">
          <li>
            <NavLink to="/about">ABOUT</NavLink>
          </li>
          <li>
            <NavLink to="/work">WORK</NavLink>
          </li>
          <li>
            <NavLink to="/contact">CONTACT</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};