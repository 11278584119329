import React, { useCallback, useEffect, useRef, useState } from "react";
import { render } from "react-dom";

import LightGallery from "lightgallery/react";
import { LightGallerySettings } from "lightgallery/lg-settings";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

const GalleryList = (props:any) => {
  const lightGallery = useRef<any>(null);
  const [items, setItems] = useState(props.fulllist);
  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return items.map((item:any, id:any) => {
      return (
        <a
          key={id}
          data-lg-size={"1400-933"}
          className="gallery-item"
          data-src={`${item}?w=1400`}
        >
          <img
          
            className="img-responsive"
            src={`${item}?w=400`}
          />
        </a>
      );
    });
  }, [items]);

  useEffect(() => {
    lightGallery.current.refresh();
    console.log("videl", lgVideo);
  }, [items]);

  return (
<>
      <LightGallery
        plugins={[lgVideo, lgZoom]}
        elementClassNames=""
        selector={".gallery-item"}
        onInit={onInit}
      >
          {props.description && <div className="flex-container">{props.description}</div>}
        {getItems()}
      </LightGallery>
      </>
  );
};

export default GalleryList;
