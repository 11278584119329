import React, { VFC, useState, useEffect, useCallback } from "react";

import { LinkIconButton } from "../components/LinkIconButton";
import { TCanvas } from "../components/TCanvas";
import { NoiseLayer } from "../components/NoiseLayer";
import { LeftMenu } from "../components/LeftMenu";
import { Link } from "react-router-dom";
import companyLogoImage from "../assets/images/companylogo-leftcorner.png";
import { client } from "../lib/sanityClient";
import { useParams } from "react-router-dom";

import GalleryList from "./GalleryList";

const WorkDetail = () => {
  const { id, category } = useParams();
  const [projectDescription, setProjectDescription] = useState("");
  const [projectTitle, setProjectTitle] = useState("");
  const [gallery, setGallery] = useState(null);
  useEffect(() => {
    fetchCollectionData();
  }, []);

  const fetchCollectionData = async (sanityClient = client) => {
    const query = `*[_type == "post" && _id == "${id}" ] {
      title,
      _id,
      _ref,
      "galleryURL": images[].asset->url,
      categories,
      publishedAt,
      "description":  body[0].children[0].text
}`;
    const collectionData = await sanityClient.fetch(query);

    // the query returns 1 object inside of an array
    await setProjectTitle(collectionData[0].title);
    await setProjectDescription(collectionData[0].description);
    await setGallery(collectionData[0].galleryURL);
  };

  return (
    <>
      <div className="page-company-logo">
        <Link to="/">
          <img src={companyLogoImage} />
        </Link>
      </div>
      <div className="work-category-name">
        <Link to={`/work/${category}`}>&#8592; {projectTitle}</Link>
      </div>
      <div className="siteContainer">
        <div className="companyLogoContainer">
          <div className="myworkDetail-container">

            

              {/* {gallery.map((galleryURL: any, id: any) => (
                <div key={id}>
                  <img src={`${galleryURL}?w=400`} />
                  asdasdasdasd
                </div>
              ))} */}

             {gallery&&(
              <GalleryList fulllist={gallery} description={projectDescription}/>
             )} 
  
          </div>
          <LeftMenu />
        </div>
      </div>
    </>
  );
};

export default WorkDetail;
