import React, { VFC, useState, useEffect } from "react";

import { LinkIconButton } from "../components/LinkIconButton";
import { TCanvas } from "../components/TCanvas";
import { NoiseLayer } from "../components/NoiseLayer";
import { LeftMenu } from "../components/LeftMenu";
import { Link } from "react-router-dom";
import btnCloseImage from "../assets/images/btn-close.png";
import leftCornerImage from "../assets/images/companylogo-leftcorner.png";
import companyNameImage from "../assets/images/company-name.png";
import { client } from "../lib/sanityClient";



const About = () => {
  const [about , setAbout] = useState([]);
  useEffect(() => {
    fetchCollectionData();
  }, []);

  const fetchCollectionData = async (sanityClient = client) => {
    const query = `*[_type == "about" ] | order(title){
      "description":  body[0].children[0].text
        }`;

    const collectionData = await sanityClient.fetch(query);


    // the query returns 1 object inside of an array
    await setAbout(collectionData[0].description);
  };

  return (
    <>
      <div className="aboutus-close-button">
        <Link to="/">
          <img src={btnCloseImage} />
        </Link>
      </div>

      <div className="siteContainer">
        <div className="aboutUsContentContainer">
          <div className="aboutUsContent">
            <div className="aboutus-company">
              {" "}
              <img src={leftCornerImage} />{" "}
              <img src={companyNameImage} />
            </div>
            <div style={{ paddingTop: "40px" }}>
             {about}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
