import React, { VFC } from "react";
import { LinkIconButton } from "./LinkIconButton";
import { TCanvas } from "./TCanvas";
import { NoiseLayer } from "./NoiseLayer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Work from "../pages/Work";
import Contact from "../pages/Contact";
import WorkCategory from "../pages/WorkCategory";
import WorkDetail from "../pages/WorkDetail";
import { stack as Menu } from 'react-burger-menu'




import { LeftMenu } from "./LeftMenu";

export const App = () => {
  return (
    <>
      <NoiseLayer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/work/:category" element={<WorkCategory />} />
          <Route path="/work/:category/:id" element={<WorkDetail />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>

    <div className="main-mobile-menu">
    <Menu right>
        <a id="home" className="menu-item" href="/">Home</a>
        <a id="about" className="menu-item" href="/about">About</a>
        <a id="about" className="menu-item" href="/work">Work</a>
        <a id="contact" className="menu-item" href="/contact">Contact</a>
      </Menu>
    </div>
   
    </>
  );
};
