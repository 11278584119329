import React, { VFC } from "react";
import { LinkIconButton } from "../components/LinkIconButton";
import { TCanvas } from "../components/TCanvas";
import { NoiseLayer } from "../components/NoiseLayer";
import { LeftMenu } from "../components/LeftMenu";
import companyLogo from "../assets/images/logo.png";



const Home = () => {
  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        <TCanvas />
      </div>

      <div className="siteContainer">
        <div className="companyLogoContainer">
          {/* <div
            className="homeCompanyLogo"
            style={{
              background: `url("logo.png") center center no-repeat`,
            }}
          ></div> */}
          <img className="homeCompanyLogo" src={companyLogo} />
          <LeftMenu />
        
        </div>
      </div>
    </>
  );
};

export default Home;
