import React, { VFC, useState, useEffect } from "react";

import { LinkIconButton } from "../components/LinkIconButton";
import { TCanvas } from "../components/TCanvas";
import { NoiseLayer } from "../components/NoiseLayer";
import { LeftMenu } from "../components/LeftMenu";
import { Link } from "react-router-dom";
import companyLogoImage from "../assets/images/companylogo-leftcorner.png";
import { client } from "../lib/sanityClient";
import { useParams } from "react-router-dom";

const WorkCategory = () => {
  const [catTitle, setCatTitle] = useState("");
  const { category } = useParams();
  const [projects, setProjects] = useState([]);
  
  useEffect(() => {
    fetchCollectionData();
    fetchTitle();
  }, []);



  const fetchCollectionData = async (sanityClient = client) => {
    const query = `*[_type == "post" && categories[0]['_ref'] == "${category}"  ] | order(publishedAt){
      title,
      _id,
      _ref,
      images,
      categories,
      publishedAt
}`;
    const collectionData = await sanityClient.fetch(query);
    // the query returns 1 object inside of an array
    await setProjects(collectionData);
  };




  const fetchTitle = async (sanityClient = client) => {
    const query = ` *[_type == "category"  && _id == "${category}"  ] | order(title){
      title,
  _id
    }`;
    const collectionData = await sanityClient.fetch(query);
    // the query returns 1 object inside of an array
    await setCatTitle(collectionData[0].title);
  };

 




  return (
    <>
      <div className="page-company-logo">
        <Link to="/">
          <img src={companyLogoImage} />
        </Link>
      </div>
      <div className="work-category-name">
        <Link to="/work">&#8592; {catTitle}</Link>
      </div>
      <div className="siteContainer">
        <div className="companyLogoContainer">
          <div className="mywork-category-container">
            <ul id="work-items">
            {projects.map((projectItem: any, id: any) => (
                <li key={id}>
                  <Link to={`/work/${category}/${projectItem._id}`}>
                    {projectItem?.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <LeftMenu />
        </div>
      </div>
    </>
  );
};

export default WorkCategory;
